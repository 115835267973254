import React from "react";
import ProgressBar from './ProgressBar';

const Forma3 = ({ nextStep, prevStep, currentStep, hideForm, formData, handleInputChange }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleChange = (e) => {
    handleInputChange('choice3', e.target.value);
  };

  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "20px", 
        height: '550px', 
        marginTop: "30px", 
        width: "50%", 
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleSubmit}>
          <h4>Ihre monatliche Stromrechnung?</h4>
          <div>
            <label htmlFor="Unter 100 CHF" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "10px" 
            }}>
              <input type='radio' name='choice' value='Unter 100 CHF' id="Unter 100 CHF" required checked={formData.choice3 === 'Unter 100 CHF'} onChange={handleChange} />Unter 100 CHF
            </label>
          </div>
          <div>
            <label htmlFor="Zwischen 100 und 200 CHF" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "10px" 
            }}>
              <input type='radio' name='choice' value='Zwischen 100 und 200 CHF' id="Zwischen 100 und 200 CHF" required checked={formData.choice3 === 'Zwischen 100 und 200 CHF'} onChange={handleChange} />Zwischen 100 und 200 CHF
            </label>
          </div>
          <div>
            <label htmlFor="Über 200 CHF" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "10px" 
            }}>
              <input type='radio' name='choice' value='Über 200 CHF' id="Über 200 CHF" required checked={formData.choice3 === 'Über 200 CHF'} onChange={handleChange} />Über 200 CHF
            </label>
          </div>
          <div>
            <label htmlFor="Ich weiß es nicht" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "10px" 
            }}>
              <input type='radio' name='choice' value='Ich weiß es nicht' id="Ich weiß es nicht" required checked={formData.choice3 === 'Ich weiß es nicht'} onChange={handleChange} />Ich weiß es nicht
            </label>
          </div>
          <div style={{ marginTop: '5px' }}>
            <button style={{ 
              width: "30%", 
              height: "40px", 
              backgroundColor: "green", 
              border: '1px solid white', 
              borderRadius: '5px', 
              marginTop: "20px" ,
              
            }} type="submit" className="next" >Nächster Schritt</button>
          </div>
          <div>
            <button type="button" className="btn left-align" style={{ float: "left",color:"#198C18" }} onClick={prevStep}>Zurück</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma3;
