
import React from 'react';

const PrivacyPolicy = () => (
    <div>
        <h1>Datenschutzerklärung</h1>
        
        <h2>1. Einführung</h2>
        <p>Willkommen bei Energy Solar. Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Diese Datenschutzerklärung erklärt, wie wir Ihre Daten erheben, verwenden, speichern und schützen, wenn Sie unsere Website besuchen oder an unseren Facebook-Werbekampagnen teilnehmen.</p>
        
        <h2>2. Datenerhebung</h2>
        <p>Wir erheben personenbezogene Daten, die Sie uns freiwillig zur Verfügung stellen, wenn Sie ein Formular auf unserer Website ausfüllen oder auf unsere Facebook-Werbung reagieren. Zu den erhobenen Daten können gehören:</p>
        <ul>
            <li>Name</li>
            <li>E-Mail-Adresse</li>
            <li>Telefonnummer</li>
            <li>Adressinformationen</li>
            <li>Sonstige relevante Informationen</li>
        </ul>

        <h2>3. Datennutzung</h2>
        <p>Die von uns erhobenen Daten werden verwendet, um:</p>
        <ul>
            <li>Ihre Anfragen zu beantworten</li>
            <li>Ihnen Informationen über unsere Dienstleistungen zu senden</li>
            <li>Ihre Bestellungen zu bearbeiten</li>
            <li>Unsere Dienstleistungen zu verbessern</li>
        </ul>

        <h2>4. Datenspeicherung</h2>
        <p>Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die Erfüllung der in dieser Datenschutzerklärung beschriebenen Zwecke erforderlich ist, sofern keine längere Speicherung gesetzlich vorgeschrieben oder erlaubt ist.</p>
        
        <h2>5. Datenweitergabe</h2>
        <p>Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, dies ist zur Erfüllung unserer Dienstleistungen erforderlich oder gesetzlich vorgeschrieben. In solchen Fällen stellen wir sicher, dass geeignete Datenschutzmaßnahmen getroffen werden.</p>
        
        <h2>6. Sicherheit</h2>
        <p>Wir setzen geeignete technische und organisatorische Maßnahmen ein, um Ihre personenbezogenen Daten vor Verlust, Missbrauch und unbefugtem Zugriff, Offenlegung, Veränderung oder Zerstörung zu schützen.</p>
        
        <h2>7. Ihre Rechte</h2>
        <p>Sie haben das Recht, auf Ihre bei uns gespeicherten personenbezogenen Daten zuzugreifen, diese zu korrigieren, zu löschen oder deren Verarbeitung einzuschränken. Darüber hinaus haben Sie das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen und Ihre Einwilligung jederzeit zu widerrufen.</p>
        
        <h2>8. Kontakt</h2>
        <p>Wenn Sie Fragen zu dieser Datenschutzerklärung oder zur Verarbeitung Ihrer personenbezogenen Daten haben, können Sie uns unter folgender E-Mail-Adresse kontaktieren:</p>
        <p>info@energysolar.ch</p>
        
        <h2>9. Änderungen dieser Datenschutzerklärung</h2>
        <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Änderungen werden auf unserer Website veröffentlicht. Bitte überprüfen Sie diese Erklärung regelmäßig auf Aktualisierungen.</p>
    </div>
);

export default PrivacyPolicy;
