import React from "react";
import ProgressBar from './ProgressBar';
import './StilizimiFormes.css';

const Forma1 = ({ nextStep, currentStep, hideForm, formData, handleInputChange }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleChange = (e) => {
    handleInputChange('choice1', e.target.value);
  };

  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "30px", 
        height: '500px', 
        marginTop: "30px", 
        width: "50%", //Width Forma Kryesore
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3 style={{ paddingTop: '20px' }}>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleSubmit}>
          <h4>Besitzen Sie Wohneigentum?</h4>
          <div className="Form1">
            <div>
              <label htmlFor="Ja" className="label-1" style={{ 
                border: "1px solid gray", 
                textAlign: 'left', 
                width: "40%", 
                height: "35px", 
                paddingLeft: "3px", 
                paddingTop: "7px", 
                borderRadius: "7px", 
                backgroundColor: 'white',
                
              }}>
                <input type='radio' name='choice' value='Ja' id="Ja" required checked={formData.choice1 === 'Ja'} onChange={handleChange} />
                Ja
              </label>
            </div>
            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
              <label htmlFor="Nein" className="label-1" style={{ 
                border: "1px solid gray", 
                textAlign: 'left', 
                width: "40%", 
                height: "35px", 
                paddingLeft: "3px", 
                paddingTop: "7px", 
                borderRadius: "7px", 
                backgroundColor: 'white' 
              }}>
                <input type='radio' name='choice' value='Nein' id="Nein" required checked={formData.choice1 === 'Nein'} onChange={handleChange} />
                Nein
              </label>
            </div>
            <button style={{ 
              width: "30%", 
              height: "40px", 
              backgroundColor: "green", 
              border: '1px solid white', 
              borderRadius: '5px',
              
            }} type="submit" className="next" >Jetzt prüfen</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma1;
