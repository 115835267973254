import React from "react";
import ProgressBar from './ProgressBar';

const Forma2 = ({ nextStep, prevStep, currentStep, hideForm, formData, handleInputChange }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleChange = (e) => {
    handleInputChange('choice2', e.target.value);
  };

  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "30px", 
        height: '500px', 
        marginTop: "30px", 
        width: "50%", 
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleSubmit}>
          <h4>Ihre Wohnsituation:</h4>
          <div style={{paddingTop:"10px"}}>
            <label htmlFor="Haus" className="label-1" style={{
               border: "1px solid gray",
                textAlign:'left',
                width:"40%",
                height:"35px",
                paddingLeft:"3px",
                paddingTop:"1px",
                borderRadius:"7px",
                backgroundColor:'white',
                marginTop:"10px"
              }}>
              <input type='radio' name='choice' value='Haus' id="Haus" required checked={formData.choice2 === 'Haus'} onChange={handleChange} />Haus 
            </label> 
          </div>
          <div style={{marginTop:'10px', marginBottom:'25px'}}>
            <label htmlFor="Wohnung" className="label-1" style={{
             border: "1px solid gray",
             textAlign:'left',
             width:"40%",
             height:"35px",
             paddingLeft:"3px",
             paddingTop:"7px",
             borderRadius:"7px",
             backgroundColor:'white',
             
             marginBottom:'10px'
          }}>
              <input type='radio' name='choice' value='Wohnung' id="Wohnung" required checked={formData.choice2 === 'Wohnung'} onChange={handleChange} />Wohnung 
            </label>
          </div>
          <div>
            <button style={{
              width:"30%",
              height:"40px",
              backgroundColor:"green",
              border:'1px solid white',
              borderRadius:'5px'
            }} type="submit" className="next"  >Nächster Schritt</button>
          </div>
          <div>
            <button type="button" className="btn"  style={{float:"left",paddingTop:"10px",color:"#198C18" }} onClick={prevStep}>Zurück</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma2;
