import React from "react";
import ProgressBar from './ProgressBar';

const Forma4 = ({ nextStep, prevStep, currentStep, hideForm, formData, handleInputChange }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleChange = (e) => {
    handleInputChange('choice4', e.target.value);
  };

  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "20px", 
        height: '500px', 
        marginTop: "30px", 
        width: "50%", 
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleSubmit}>
          <h4>Haben Sie Interesse an staatlichen Zuschüssen?</h4>
          <div>
            <label htmlFor="Ja" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "40px" 
            }}>
              <input type='radio' name='choice' value='Ja' id="Ja" required checked={formData.choice4 === 'Ja'} onChange={handleChange} />Ja
            </label>
          </div>
          <div>
            <label htmlFor="Nein" className="label-1" style={{ 
              border: "1px solid gray", 
              textAlign: 'left', 
              width: "40%", 
              height: "35px", 
              paddingLeft: "3px", 
              paddingTop: "7px", 
              borderRadius: "7px", 
              backgroundColor: 'white', 
              marginTop: "10px" 
            }}>
              <input type='radio' name='choice' value='Nein' id="Nein" required checked={formData.choice4 === 'Nein'} onChange={handleChange} />Nein
            </label>
          </div>
          <div>
            <button style={{ 
              width: "30%", 
              height: "40px", 
              backgroundColor: "green", 
              border: '1px solid white', 
              borderRadius: '5px', 
              marginTop: "20px" ,
              className:"next"
            }} type="submit" className="next" >Nächster Schritt</button>
          </div>
          <div>
            <button type="button" className="btn" style={{ float: "left",color:"#198C18" }} onClick={prevStep}>Zurück</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma4;
