import React from "react";
import ProgressBar from './ProgressBar';

const Forma5 = ({ nextStep, prevStep, currentStep, hideForm, formData, handleInputChange }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleChange = (e) => {
    handleInputChange('postcode', e.target.value);
  };

  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "20px", 
        height: '450px', 
        marginTop: "30px", 
        width: "50%", 
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleSubmit}>
          <h4>Ihre Postleitzahl:</h4>
          <div>
            <input type='text' name='postcode' id="postcode" required value={formData.postcode} onChange={handleChange} style={{
              backgroundColor: "white",
              width: "30%",
              borderColor: "green",
              borderRadius: "5px",
              marginTop: "15px"
            }} />
          </div>
          <div>
            <button style={{
              width: "30%",
              height: "40px",
              backgroundColor: "green",
              border: '1px solid white',
              borderRadius: '5px',
              marginTop: "50px",
              className:"next"
            }} type="submit" className="next" >Nächster Schritt</button>
          </div>
          <div>
            <button type="button" className="btn" style={{ float: "left", paddingTop: "10px" , color:"#198C18"}} onClick={prevStep}>Zurück</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma5;
