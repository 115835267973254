import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ currentStep }) => {
  const progressPercentage = Math.min((currentStep - 1) / 5 * 100, 100); 

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progressPercentage}%` }}>
        Fortschritt: {progressPercentage}%
      </div>
    </div>
  );
};

export default ProgressBar;
