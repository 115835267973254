import React from 'react';
import { FaSun } from 'react-icons/fa';
import { MdOutlineHighQuality, MdOutlineSolarPower } from 'react-icons/md'; 
import { GiSolarPower } from "react-icons/gi";
import { LiaSolarPanelSolid } from "react-icons/lia";
import { TbSolarElectricity } from "react-icons/tb";
import './ListItem.css'; 

const iconMapping = {
  "Hohe Effizienz": <MdOutlineSolarPower />, 
  "Überlegene Qualität:": <GiSolarPower />,
  "Umweltauswirkungen:": <LiaSolarPanelSolid />,
  "Kostenersparnis:": <GiSolarPower />,
  "Energieunabhängigkeit:": <TbSolarElectricity />,
  "Wertsteigerung der Immobilie:": <MdOutlineSolarPower />
};

const ListItem = ({ title, description }) => {
  const icon = iconMapping[title] || <FaSun />; 
  return (
    <div className="list-item">
      <div className="list-item-icon">
        {icon}
      </div>
      <div className="list-item-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ListItem;
