// src/App.js
import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import Forma1 from './Forma1';
import Forma2 from './Forma2';
import Forma3 from './Forma3';
import Forma4 from './Forma4';
import Forma5 from './Forma5';
import Forma6 from './Forma6';
import backgroundImage from './a.jpg';
import ListItem from './ListItem';
import './App.css';
import './ListItem.css';
import foto from './solar.png';
import './Foto.css';
import PrivacyPolicy from './privacy-policy';

const jsonData = [
    {
        title: "Hohe Effizienz",
        description: "Unsere Solarpanels sind mit modernster Technologie und hochwertigen Materialien gebaut und darauf ausgelegt, verschiedenen Wetterbedingungen standzuhalten und langlebige Leistung zu gewährleisten."
    },
    {
        title: "Überlegene Qualität:",
        description: "Mindestens 30 Jahre Lebensdauer, mehr als 10 % bis 15% zusätzlicher Energieertrag auf Dächern und in Schattenlagen im Vergleich zu herkömmlichen Modulen."
    },
    {
        title: "Umweltauswirkungen:",
        description: "Durch die Wahl von Solarenergie tragen Sie zur Reduzierung von CO2-Emissionen bei und fördern eine sauberere Umwelt. Solarenergie ist eine erneuerbare Ressource, die dazu beiträgt, den Klimawandel zu bekämpfen und unsere Abhängigkeit von fossilen Brennstoffen zu verringern."
    },
    {
        title: "Kostenersparnis:",
        description: "Mit Solarenergie können Sie Ihre monatlichen Stromrechnungen erheblich senken. Darüber hinaus machen verschiedene staatliche Anreize und Rabatte die anfängliche Investition in Solarpanels erschwinglicher, was zu noch größeren Einsparungen führt."
    },
    {
        title: "Energieunabhängigkeit:",
        description: "Erzeugen Sie Ihren eigenen Strom und werden Sie weniger abhängig vom Netz. Mit Solarpanels können Sie auch bei Stromausfällen eine ununterbrochene Energieversorgung genießen, was für Ruhe und Energiesicherheit sorgt."
    },
    {
        title: "Wertsteigerung der Immobilie:",
        description: "Häuser, die mit Solarsystemen ausgestattet sind, haben in der Regel einen höheren Immobilienwert und verkaufen sich schneller. Potenzielle Käufer erkennen die Vorteile niedrigerer Energiekosten und eines geringeren CO2-Fußabdrucks."
    }
];

const App = () => {
    return (
        <Router basename={"/"}>
            <ToastContainer />

            <Routes>
                <Route path="/" element={<AppContent />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    );
};

const AppContent = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        choice1: '',
        choice2: '',
        choice3: '',
        choice4: '',
        postcode: '',
        firstName: '',
        lastName: '',
        phone: ''
    });
    const [isVisible, setIsVisible] = useState(true);
    const backgroundRef = useRef(null);

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);
    const hideForm = () => setIsVisible(false);
    const showForm = () => {
        setIsVisible(true);
        if (backgroundRef.current) {
            backgroundRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleInputChange = (field, value) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
    };

    const handleFinalSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_4txtisr', 'template_054bpn3', e.target, 'KTvVbxWbqS9bR5Ci2')
            .then((result) => {
                toast.success('Email erfolgreich gesendet!');
                console.log('Email successfully sent!', result.text);
                hideForm(); // Automatically hide the form after successful submission
            }, (error) => {
                toast.error('Failed to send email. Please try again later.');
                console.log('Failed to send email. Error:', error.text);
            });
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Forma1 nextStep={nextStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
            case 2:
                return <Forma2 nextStep={nextStep} prevStep={prevStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
            case 3:
                return <Forma3 nextStep={nextStep} prevStep={prevStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
            case 4:
                return <Forma4 nextStep={nextStep} prevStep={prevStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
            case 5:
                return <Forma5 nextStep={nextStep} prevStep={prevStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
            case 6:
                return <Forma6 prevStep={prevStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} handleFinalSubmit={handleFinalSubmit} />;
            default:
                return <Forma1 nextStep={nextStep} currentStep={currentStep} hideForm={hideForm} formData={formData} handleInputChange={handleInputChange} />;
        }
    };

    return (
        <>
            <div className="background" style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'auto',
                minHeight: '100vh',
                padding: '20px',
            }}
                id='foto'
                ref={backgroundRef}>
                {isVisible && (
                    <div className="form-popup">
                        {renderStep()}
                    </div>
                )}
            </div>
            <div className='container-old' style={{ marginTop: '2%' }} >
                <h1 className='text-center' style={{ fontFamily: 'didot,serif' }}>25 JAHREN PRODUKT- UND 30 JAHREN ERTRAGSGARANTIE</h1>
            </div>
            <div className="list-container" style={{ marginLeft: '20%', marginTop: "50px" }}>
                {jsonData.map((data, index) => (
                    <ListItem key={index} {...data} />
                ))}
            </div>
            <div className='container-fluid'>
                <img src={foto} style={{ height: '400px' }} alt="Solar Panel" />
            </div>
            <div className='container-fluid text-center'>
                <h1>Warum Energy Solar wählen?</h1>
            </div>
            <div className="container text-center" style={{ border: '0px' }}>
                <div className="row align-items-start">
                    <div className="col-lg-3 col-sm-6">
                        <p onClick={showForm}><b>Fachmännische Installation:</b> Zertifizierte Fachleute sorgen für nahtlose Installation und optimale Leistung.</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p onClick={showForm}><b>Umfassender Support:</b> Von der Beratung bis zur Wartung bieten wir umfassenden Support.</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p onClick={showForm}><b>Individuelle Lösungen:</b> Angepasste Systeme für Ihre spezifischen Bedürfnisse und Vorlieben.</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p onClick={showForm}><b>Aufruf zur Aktion:</b> Treten Sie der Solar-Revolution bei! Verpassen Sie nicht die Gelegenheit, die Vorteile der Solarenergie zu genießen.</p>
                    </div>
                    <div className="container-fluid text-center" style={{ border: '0px' }}>
                        <div className='row'>
                            <div className='col'>
                                <button className="button-17" role="button" onClick={showForm}>Melden Sie sich für eine kostenlose Beratung an</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default App;
