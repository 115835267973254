import React from "react";
import ProgressBar from './ProgressBar';

const Forma6 = ({ prevStep, currentStep, hideForm, formData, handleInputChange, handleFinalSubmit }) => {
  return (
    <div>
      <div className="container text-center" style={{ 
        border: '3px solid green', 
        borderRadius: '25px', 
        paddingTop: "20px", 
        height: '540px', 
        marginTop: "30px", 
        width: "70%", 
        backgroundColor: 'white', 
        opacity: '0.9' 
      }}>
        <button onClick={hideForm} style={{ float: 'right', backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer' }}>X</button>
        <h3>Erhalten Sie Ein kostenloses Angebot für Solarmodule</h3>
        <ProgressBar currentStep={currentStep} />
        <form onSubmit={handleFinalSubmit}>
          <h4>Ihre Kontaktdaten?</h4>
          <div>
            Vorname <br />
            <input 
              className="label-2" 
              type='text' 
              name='firstName' 
              value={formData.firstName} 
              onChange={(e) => handleInputChange(e.target.name, e.target.value)} 
              required 
            />
          </div>
          <div>
            Nachname <br />
            <input 
              className="label-2" 
              type='text' 
              name='lastName' 
              value={formData.lastName} 
              onChange={(e) => handleInputChange(e.target.name, e.target.value)} 
              required 
            />
          </div>
          <div>
            Telefonnummer <br />
            <input 
              type='text' 
              className="label-2" 
              name='phone' 
              value={formData.phone} 
              onChange={(e) => handleInputChange(e.target.name, e.target.value)} 
              required 
            />
          </div>
          
      
          <input type="hidden" name="Besitzen Sie Wohneigentum?" value={formData.choice1} />
          <input type="hidden" name="Ihre Wohnsituation?" value={formData.choice2} />
          <input type="hidden" name="Ihre monatliche Stromrechnung?" value={formData.choice3} />
          <input type="hidden" name="Haben Sie Interesse an staatlichen Zuschüssen?" value={formData.choice4} />
          <input type="hidden" name="postcode" value={formData.postcode} />
          
          <div>
            <button style={{ 
              width: "30%", 
              height: "40px", 
              backgroundColor: "green", 
              border: '1px solid white', 
              borderRadius: '5px', 
              marginTop: "45px" 
            }} type="submit" className="next">Jetzt prüfen</button>
          </div>
          <div>
            <button type="button" className="btn" style={{ float: "left", paddingTop: "10px" }} onClick={prevStep}>Zurück</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forma6;